var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"mb-2",attrs:{"title":_vm.actionLabel(_vm.action.action.type)}},[_c('div',{staticClass:"flex flex-row items-center justify-end"},[_c('a-button',{staticClass:"text-primary",attrs:{"icon":"arrow-up","disabled":_vm.isMoveUpButtonDisabled},on:{"click":function($event){return _vm.$emit('moveUp')}}}),_c('a-button',{staticClass:"text-primary ml-2",attrs:{"icon":"arrow-down","disabled":_vm.isMoveDownButtonDisabled},on:{"click":function($event){return _vm.$emit('moveDown')}}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"danger","icon":"delete"},on:{"click":function($event){return _vm.$emit('remove')}}})],1),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action,
        'prefix': 'post_action_',
        'step': _vm.StepUnit.Second,
        'key': 'delay'
      }
    }}}),(_vm.action.action.type !== _vm.PostActionType.DeletePostAction && _vm.action.action.type !== _vm.PostActionType.UnpinChatMessageAction)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args':{
        'model': _vm.action.action.message,
        'key':'variants',
        'prefix': 'post_',
        'validation': 'required',
        'settingsStructure': {
          'disable_link_preview': false,
          'disable_notify': false,
          'pin': false,
          'remove_previous': false,
        },
        'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
        'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
        'has-media': false,
        'base-api-url': 'api.chatkeeper.info/cabinet',
        'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }